import React from 'react';
import { graphql } from 'gatsby';
import { Container, Row, Col } from 'reactstrap';
import Img from 'gatsby-image';
import SEO from '../components/seo';
import '../styles/theme.scss';

export const query = graphql`
  query($slug: String!) {
    contentfulSchoolProject(slug: { eq: $slug }) {
      projectName
      category
      role
      timeline
      technologiesUsed
      organization
      previewLink
      description {
        description
      }
      process {
        process
      }
      learningOutcomes {
        learningOutcomes
      }
      featuredImage {
        fluid {
          ...GatsbyContentfulFluid_tracedSVG
        }
        title
        file {
          url
        }
      }
      images {
        fluid {
          ...GatsbyContentfulFluid_tracedSVG
        }
        title
        file {
          url
        }
      }
    }
  }
`;
const Project = (props) => {
  let process;
  let outcomes;
  if (props.data.contentfulSchoolProject.process) {
    process = (
      <>
        <h3>Process</h3>
        <p>{props.data.contentfulSchoolProject.process.process}</p>
      </>
    );
  }
  if (props.data.contentfulSchoolProject.learningOutcomes) {
    outcomes = (
      <>
        <h3>Learning Outcomes</h3>
        <p>
          {props.data.contentfulSchoolProject.learningOutcomes.learningOutcomes}
        </p>
      </>
    );
  }
  return (
    <>
      <SEO title="School Project | BCIT Portfolio 2019 | Jimmy Truong" />
      <Container fluid>
        <Row className="pageHeader">
          <Col>
            <h1>{props.data.contentfulSchoolProject.projectName}</h1>
          </Col>
          <Col>
            <h5>{props.data.contentfulSchoolProject.category}</h5>
          </Col>
        </Row>
        <Row className="ProjectsContainer">
          <Col md="12" className="ProjectInfo">
            <h4>
              Timeline:{' '}
              <span>{props.data.contentfulSchoolProject.timeline}</span>
            </h4>
            <h4>
              Role: <span>{props.data.contentfulSchoolProject.role}</span>
            </h4>
            <h4>
              Technologies used:{' '}
              <span>{props.data.contentfulSchoolProject.technologiesUsed}</span>
            </h4>
          </Col>
        </Row>
        <hr />
        <Row className="ProjectDetail">
          <Col xl="6" lg="12">
            {props.data.contentfulSchoolProject.images ? (
              props.data.contentfulSchoolProject.images.map((item, index) => (
                <Img
                  key={index}
                  fluid={item.fluid}
                  className="project-img"
                  alt={item.title}
                />
              ))
            ) : (
              <Img
                fluid={props.data.contentfulSchoolProject.featuredImage.fluid}
              />
            )}
          </Col>
          <Col xl="6" lg="12">
            <h3>Description</h3>
            <p>{props.data.contentfulSchoolProject.description.description}</p>
            {process}
            {outcomes}
            <a
              href={props.data.contentfulSchoolProject.previewLink}
              className="btn btn-outline-info"
              target="_blank"
              rel="noopener noreferrer"
            >
              Live Preview
            </a>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Project;
